import React from 'react'

import Layout from '../components/layout'
import AnimatedHr from '../components/animatedHr'

import mascot from '../images/nova-electronics-mascot.png'

const AboutUs = () => (
  <Layout>
    <div className="headerImage about">
      <div className="headerImageContent">
        <div className="headerImageText">
          <div>
            <h1>About Us</h1>
            <div style={{ display: 'inline-block' }}>
              <AnimatedHr />
            </div>
            <p>
              Your Sheffield Experts in repairing electronic devices from
              catering equipment to laptops.
            </p>
          </div>
        </div>
      </div>
    </div>

    <section>
      <div className="container">
        <div className="row">
          <div className="columns six">
            <img src={mascot} />
          </div>
          <div className="columns six">
            <p>
              We have been repairing electronic equipment since 1979 and have a
              broad and in depth knowledge of electronics. From small electronic
              keys to CNC machines, it is electronics that makes it work.
            </p>
            <p>
              Our pride comes from saving you money by enabling you to repair
              instead of replace, giving your familiar device a new lease of
              life. We are committed to helping local businesses do well and to
              providing a personal, professional service to all our customers.
            </p>
            <p>
              Faults with electronic devices can, in many cases, be easily fixed
              and save you money. However, if this is not possible, we will
              discuss your options with you.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default AboutUs
